<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>数字化转型</el-breadcrumb-item>
                <el-breadcrumb-item>服务优势</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                <el-table-column prop="image" align="center" label="图片">
                    <template slot-scope="{ row }">
                        <img :src="row.image" style="width: 100%; max-height: 200px" />
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 弹出框 -->
        <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" :show-close="false" :before-close="bfc">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="标题">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input type="textarea" autosize resize="none" v-model.trim="form.describe"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <upload-img :src="form.image" @removeSrc="() => (form.image = '')" ref="image"></upload-img>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="yes">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import uploadImg from "@/components/customs/uploadImg.vue";

export default {
    components: { uploadImg },
    data() {
        return {
            tableData: [],
            form: {},
            dialogVisible: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/marketing/dtlist");
            return data;
        },
        openSet(row) {
            this.form = { ...row };
            this.dialogVisible = true;
        },
        async yes() {
            let { form } = this;
            form.image = this.$refs.image.url;
            let { data } = await axios.put("/api/marketing/dtup", qs.stringify(form));
            this.$message({
                type: data.code == 200 ? "success" : "error",
                message: data.msg,
            });
            this.dialogVisible = false;
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    /deep/.edit {
        border-radius: 20px;
    }
}
</style>
